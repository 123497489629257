import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/',
    redirect: { name: 'login' },
  },
  {
    name: 'auth',
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      // {
      //   name: 'signup',
      //   path: 'signup',
      //   component: () => import('../pages/auth/Signup.vue'),
      // },
      // {
      //   name: 'recover-password',
      //   path: 'recover-password',
      //   component: () => import('../pages/auth/RecoverPassword.vue'),
      // },
      // {
      //   name: 'recover-password-email',
      //   path: 'recover-password-email',
      //   component: () => import('../pages/auth/CheckTheEmail.vue'),
      // },
      // {
      //   path: '',
      //   redirect: { name: 'login' },
      // },
    ],
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/assetManagement',
    component: AppLayout,
    children: [
      {
        name: 'assetTagList',
        path: '/assetManagement/assetTagList',
        component: () => import('../pages/assetManagement/assetTagList/index.vue'),
      },
      {
        name: 'assetList',
        path: '/assetManagement/assetList',
        component: () => import('../pages/assetManagement/assetList/index.vue'),
      },
      {
        name: 'assetEdit',
        path: '/assetManagement/assetEdit',
        component: () => import('../pages/assetManagement/assetList/edit.vue'),
      },
    ],
  },
  {
    path: '/themeManagement',
    component: AppLayout,
    children: [
      {
        name: 'themeTagList',
        path: '/themeManagement/themeTagList',
        component: () => import('../pages/themeManagement/themeTagList/index.vue'),
      },
      {
        name: 'themeList',
        path: '/themeManagement/themeList',
        component: () => import('../pages/themeManagement/themeList/index.vue'),
      },
      {
        name: 'defaultThemEdit',
        path: '/themeManagement/defaultThemEdit',
        component: () => import('../pages/themeManagement/themeList/defaultThemEdit.vue'),
      },
      {
        name: 'themeEdit',
        path: '/themeManagement/themeEdit',
        component: () => import('../pages/themeManagement/themeList/edit.vue'),
      },
      // groundEdit
      {
        name: 'groundEdit',
        path: '/themeManagement/groundEdit',
        component: () => import('../pages/themeManagement/themeList/groundEdit.vue'),
      },
      // {
      //   name: 'edit3',
      //   path: '/edit3',
      //   component: () => import('../pages/edit3.vue'),
      // },
    ],
  },
  {
    path: '/accountManagement',
    name: 'accountManagement',
    component: AppLayout,
    children: [
      {
        name: 'accountList',
        path: '/accountManagement/accountList',
        component: () => import('../pages/accountManagement/accountList/index.vue'),
      },
      {
        name: 'menuList',
        path: '/accountManagement/menuList',
        component: () => import('../pages/accountManagement/menuList/index.vue'),
      },
      {
        name: 'twomenuList',
        path: '/accountManagement/twomenuList',
        component: () => import('../pages/accountManagement/menuList/twomenu.vue'),
      },
      {
        name: 'roleList',
        path: '/accountManagement/roleList',
        component: () => import('../pages/accountManagement/role/index.vue'),
      },
      {
        name: 'DistributionMenu',
        path: '/accountManagement/DistributionMenu',
        component: () => import('../pages/accountManagement/role/DistributionMenu.vue'),
      },
    ],
  },
  {
    name: 'faq',
    path: '/faq',
    component: () => import('../pages/faq/FaqPage.vue'),
  },
  {
    path: '/shopManagement',
    name: 'shopManagement',
    component: AppLayout,
    children: [
      {
        name: 'shopList',
        path: '/shopManagement/shopList',
        component: () => import('../pages/shopManagement/shopList/index.vue'),
      },
      {
        name: 'ShopAssociatedTerminals',
        path: '/shopManagement/ShopAssociatedTerminals',
        component: () => import('../pages/shopManagement/ShopAssociatedTerminals/index.vue'),
      },
      {
        name: 'ShopAssociatedAccount',
        path: '/shopManagement/ShopAssociatedAccount',
        component: () => import('../pages/shopManagement/ShopAssociatedAccount/index.vue'),
      },
    ],
  },
  {
    path: '/TerminalManagement',
    name: 'TerminalManagement',
    component: AppLayout,
    children: [
      {
        name: 'TerminalList',
        path: '/TerminalManagement/TerminalList',
        component: () => import('../pages/TerminalManagement/TerminalList/index.vue'),
      },
      {
        name: 'TerminalAssociatedTheme',
        path: '/TerminalManagement/TerminalAssociatedTheme',
        component: () => import('../pages/TerminalManagement/TerminalAssociatedTheme/index.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
