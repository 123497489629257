export interface INavigationRoute2 {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute2[]
}

//门店管理员菜单
export default {
  root: {
    name: '/',
    displayName: 'Storemanager.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    // {
    //   name: 'shopManagement',
    //   displayName: 'menu.shopManagement',
    //   meta: {
    //     icon: 'group',
    //   },
    //   children: [
    //     {
    //       name: 'shopList',
    //       displayName: 'menu.shopList',
    //     },
    //     {
    //       name: 'ShopAssociatedTerminals',
    //       displayName: 'menu.ShopAssociatedTerminals',
    //     },
    //     {
    //       name: 'ShopAssociatedAccount',
    //       displayName: 'menu.ShopAssociatedAccount',
    //     },
    //   ],
    // },
    //终端管理
    {
      name: 'TerminalManagement',
      displayName: 'menu.TerminalManagement',
      meta: {
        icon: 'group',
      },
      children: [
        {
          name: 'TerminalList',
          displayName: 'menu.TerminalList',
        },
        {
          name: 'TerminalAssociatedTheme',
          displayName: 'menu.TerminalAssociatedTheme',
        },
      ],
    },
    {
      name: 'assetManagement',
      displayName: 'menu.assetManagement',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'assetTagList',
          displayName: 'menu.assetTagList',
        },
        {
          name: 'assetList',
          displayName: 'menu.assetList',
        },
      ],
    },
    {
      name: 'themeManagement',
      displayName: 'menu.themeManagement',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'themeTagList',
          displayName: 'menu.themeTagList',
        },
        {
          name: 'themeList',
          displayName: 'menu.themeList',
        },
      ],
    },
    {
      name: 'accountManagement',
      displayName: 'menu.accountManagement',
      meta: {
        icon: 'group',
      },
      children: [
        {
          name: 'accountList',
          displayName: 'menu.accountList',
        },
      ],
    },
  ] as INavigationRoute2[],
}
