import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import { useGlobalInit } from './components/globalComposables'
// import { Platformmanager,Storemanager,Storeuser} from '../public/config'

const app = createApp(App)
app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))

app.config.globalProperties.$init = useGlobalInit().notify
const loadConfig = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = `${import.meta.env.BASE_URL}config.js` // 加载 public/config.js
    script.onload = () => {
      resolve(window.APP_CONFIG)
    }
    script.onerror = () => {
      reject(new Error('配置文件加载失败'))
    }
    document.head.appendChild(script)
  })
}

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

loadConfig()
  .then((config) => {
    app.config.globalProperties.$Platformmanager = Platformmanager // 挂载到全局属性
    app.config.globalProperties.$Storemanager = Storemanager
    app.config.globalProperties.$Storeuser = Storeuser
    app.mount('#app')
  })
  .catch((error) => {
    console.error(error)
    // 处理加载配置文件失败的情况
  })
